import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteOffSharpIcon from '@mui/icons-material/ContentPasteOffSharp';
import classNames from 'classnames';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import {Table, TableBody, TableCell, CircularProgress,
   TableRow, TableHead, TableContainer,
  Button, Dialog, DialogActions, 
  Pagination, DialogTitle, Backdrop, DialogContent} from '@mui/material';
import { toast } from "react-hot-toast";
import request from '../services';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
  
  const DenseTable = ({hesapId, yeniAlici}) => {
    const [hsModal, setHsModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [hSilSpinner, setHsilSpinner] = React.useState(true);
    const [alici, setAlici] = React.useState({value:'',name:''});
    const [alicilar, setAlicilar] = React.useState([]);
    const [topPage, setTopPage] = React.useState(0);
    const [backdrop, setBackdrop] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [ara, setAra] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [kullanilmayan, setKullanilmayan] = React.useState(false);
    const [updateModal, setUpdateModal] = React.useState(false);
    const [adresDis, setAdresDis] = React.useState(true);
    const [adres, setAdres] = React.useState('');
    const [indirLoading, setIndirLoading] = React.useState(false);
    
    const deleteAlici = (durum)=>{
        const toastId = toast.loading(durum ? 'E-posta adresleri siliniyor lütfen bekleyin.' :alici.name + ' Siliniyor lütfen bekleyin.')
        setBackdrop(true)
        setDeleteModal(false);

        request.postAxios(durum ? 'Alici/DeleteAlici?id=&hesapId='+hesapId : 'Alici/DeleteAlici?id=' + alici.value + '&hesapId=').then((res)=>{
          toast.success(durum?'E-posta adresleri başarılı bir şekilde silindi.':'E-posta adresi başarılı bir şekilde silindi.',{duration:2500, id:toastId})
          
          setHsModal(false);
          setHsilSpinner(true)
          GetALici()
        }).catch((err)=>{
          if(err?.response?.status === 401){
            window.location.href = '/login'
            return;
        }
          toast.error(durum ? 'E-posta adresleri silinirken bir hata oluştu.': alici.name + ' Silinirken bir hata oluştu.',{duration:3000, id:toastId})
          setHsModal(false);
          setDeleteModal(false);
          setHsilSpinner(true)
          setBackdrop(false)
        })
    }

    function GetALici(page){
      let url='Alici/GetAlicilar?hesapId=' + hesapId + '&page='
      url += page ? page : 1
      url += '&arama='+search;
      url += '&durum=' + kullanilmayan

      request.getAxios(url).then((res)=>{
        setLoading(false)
        setAlicilar(res.data.alici)
        setTopPage(res.data.topPage);
        setBackdrop(false)
      }).catch((err)=>{
        if(err?.response?.status === 401){
          window.location.href = '/login'
          return;
      }
        setLoading(false)
        setBackdrop(false)
        const hata ="Alıcılar yüklenirken bir hata oluştu."
        setMessage(typeof err?.response?.data =='string' ? err.response.data || hata:hata)
        toast.error(typeof err?.response?.data =='string' ? err.response.data || hata:hata, {duration:3000})
      })
    }

    React.useEffect(()=>{
        GetALici()
    },[yeniAlici])

    React.useEffect(()=>{
      if(ara){
        setBackdrop(true)
          const ara = setTimeout(() => {
            GetALici()
          }, 1000);
    
          return ()=>clearTimeout(ara)
      }
    },[search])

    const changePage=(page)=>{
      setBackdrop(true)
      GetALici(page)
    }

    useEffect(()=>{
      GetALici()
    },[kullanilmayan])

    function UpdateAlici(){
      setUpdateModal(false)
      setAdres('')
      const toastId=toast.loading('E-posta adresi düzenleniyor.')

      request.postAxios('Alici/UpdateAlici?id='+alici.value+'&adres='+adres).then((res)=>{
        toast.success('E-posta adresi düzenlendi.',{id:toastId,duration:2500})
        GetALici()
      }).catch(()=>{
        toast.error('E-posta adresi düzenlenirken bir hata oluştu.',{id:toastId, duration:3000})
      })
    }

    async function DownloadAlicilar(){
      setIndirLoading(true);
      await request.getAxios('Alici/DownloadAlicilar?hesapId='+hesapId).then((res)=>{
        const d =res.data.map((item)=>({"E-posta Adresi":item}));
        
        const ws = XLSX.utils.json_to_sheet(d)
        const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
        const excelBuffer =XLSX.write(wb, {bookType:'xlsx', type:'array'});
        const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        FileSaver.saveAs(data, 'E-posta adresleri.xlsx')
      }).catch(()=>{})  
      setIndirLoading(false)
    }

    useEffect(()=>{
      if(adres.includes('@'))
        setAdresDis(false)
      else
        setAdresDis(true)
    },[adres])

  return (
    <>
    <div className='d-md-flex justify-content-between'>
      <div className='mb-2 d-flex'>
        <FormGroup className='mx-auto'>
          <FormControlLabel control={<Switch checked={kullanilmayan} 
          onChange={()=>{setKullanilmayan(!kullanilmayan)}}/>} label="Henüz kullanılmayanlar" />
        </FormGroup>
      </div>
      <div className='d-md-flex gap-2'>
        <div className='mb-2'>
          <Button
          endIcon={<DeleteIcon />}
          variant="outlined"
          onClick={()=>setDeleteModal(true)}
          fullWidth color='error'>
            Tüm Alıcıları Sil
          </Button>
        </div>
        <div className='mb-2'>
          <LoadingButton
              loading={indirLoading}
              loadingPosition="end"
              endIcon={<DownloadForOfflineIcon />}
              variant="outlined"
              onClick={DownloadAlicilar}
              fullWidth
          >Alıcıları İndir</LoadingButton>
        </div>
      </div>
    </div>
    <TableContainer component={Paper} className='position-relative'>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop} className='position-absolute' style={{backgroundColor:'#cfcfcf80'}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table sx={{ minWidth: 400 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Adres</TableCell>
            <TableCell align='center'>Düzenle</TableCell>
            <TableCell align='center'>Sil</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alicilar.length > 0 ? (alicilar?.map((row) => (
            <TableRow
              key={row.ad}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.adres}</TableCell>
              <TableCell align='center' style={{width:'74px'}}>
                <IconButton aria-label="update" size='small' color="" 
                onClick={()=>{setAlici({value:row.id, name:row.adres}); setUpdateModal(true); setAdres(row.adres)}}>
                    <EditIcon fontSize="small"/>
                </IconButton>
              </TableCell>
              <TableCell align='center' style={{width:'74px'}}>
                <IconButton aria-label="delete" size='small' color="error" 
                onClick={()=>{setAlici({value:row.id, name:row.ad || row.adres}); setHsModal(true)}}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))) : (<TableRow>
              <TableCell align='center' colSpan={8}>
                {loading? (<CircularProgress size={35} color="inherit" />): (
                    <span className='fs-3 text-black-50 d-flex justify-content-center'>
                      <ContentPasteOffSharpIcon fontSize='large' className='me-2'>
                        </ContentPasteOffSharpIcon>{message || 'Gösterilecek veri yok.'}
                      </span>
                )}
              </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
    <div className="row justify-content-between">
      <div className='col-md-auto mt-2'>
        <Paper className='d-flex'>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Ara"
                size='small' fullWidth
                onChange={(event)=> {setSearch(event.target.value); setAra(true)}}
            />
            <IconButton type="button" size='small' sx={{ p: '10px' }} aria-label="search"
            onClick={()=>GetALici()}>
                <SearchIcon />
            </IconButton>
        </Paper>
      </div>
      <div className='col-md-auto mt-2'>
        <Pagination count={topPage} variant="outlined" 
        className={classNames('d-flex',{'d-none': topPage > 1 ? false : true})} 
        onChange={(event, value)=> changePage(value)}/>
      </div>
    </div>

          <div>
            <Dialog
                open={hsModal}
                //onClose={()=>setHsModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {alici.name + " Silinecek"}
                </DialogTitle>
                <DialogActions className='d-flex justify-content-around'>
                <Button disabled={!hSilSpinner} onClick={()=>setHsModal(false)}>İptal</Button>
                <Button onClick={()=>{deleteAlici(false); setHsilSpinner(false)}} hidden={!hSilSpinner} autoFocus color='error'>
                      SİL
                </Button>
                <CircularProgress className='mx-4' hidden={hSilSpinner} color="error" size={24}/>
                </DialogActions>
            </Dialog> 
        </div>
        <Dialog
                open={updateModal}
                //onClose={()=>setHsModal(false)}
                aria-labelledby="alert-dialog-title-update"
                aria-describedby="alert-dialog-description-update"
                fullWidth={true}
                maxWidth={"xs"}>
                <DialogTitle id="alert-dialog-title-update">
                E-posta Adresi Düzenle
                </DialogTitle>
                <DialogContent>
                <TextField
                        onChange={(event)=>{
                            setAdres(event.target.value)
                        } }
                        margin="dense"
                        id="ad"
                        value={adres}
                        label="E-posta adresi"
                        type="mail"
                        fullWidth
                        size="small"
                    />
                </DialogContent>
                <DialogActions className='d-flex justify-content-around'>
                <Button onClick={()=>setUpdateModal(false)}>İptal</Button>
                <Button onClick={()=>{UpdateAlici(); setUpdateModal(false)}} disabled={adresDis}>
                      Kaydet
                </Button>
                </DialogActions>
            </Dialog> 

            <Dialog
                open={deleteModal}
                //onClose={()=>setHsModal(false)}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">
                Tüm E-posta adresleri silinecek.
                </DialogTitle>
                <DialogActions className='d-flex justify-content-around'>
                <Button onClick={()=>setDeleteModal(false)}>İptal</Button>
                <Button onClick={()=>{deleteAlici(true);}} autoFocus color='error'>
                      SİL
                </Button>
                </DialogActions>
            </Dialog> 
    </>
  );
}

export default DenseTable;